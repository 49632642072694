import {Injectable} from '@angular/core';
import {CustomerProvider} from '../transport/customer.provider';
import {LocalStorageService} from 'ngx-webstorage';
import {CustomerSmsLoginResponse} from '../transport/models/customer/customer-sms-login.response';
import {LoginDialog, LoginDialogResult, LoginDialogState} from '../dialogs/login-dialog/login.dialog';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {decodeJwt} from 'jose';
import {OrderService} from './order.service';
import {BehaviorSubject} from 'rxjs';
import Bugsnag from '@bugsnag/js';
import {DevUtils} from '../utils/dev.utils';
import {CartService} from './cart.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  public loginDialogRef?: MatDialogRef<LoginDialog, LoginDialogResult> | null;
  public isSignedIn$;
  private isSignedIn = new BehaviorSubject(false);
  private customerId = new BehaviorSubject<CustomerIdPair | null>(null);
  private pendingCustomerId: Promise<string> | null = null;

  private _currentStoreChainId: string | null = null;
  public get currentStoreChainId() {
    return this._currentStoreChainId;
  }

  constructor(private customerProvider: CustomerProvider,
              private storageService: LocalStorageService,
              private dialog: MatDialog,
              private orderService: OrderService,
  ) {
    this.isSignedIn$ = this.isSignedIn.asObservable();
    this.customerId.subscribe((id) => Bugsnag.addMetadata('Auth', {customerId: id}));
    this.isSignedIn.subscribe(value => Bugsnag.addMetadata('Auth', {isSignedIn: value}));
  }

  initCustomerFromAuth(storeChainId: string) {
    this._currentStoreChainId = storeChainId;

    if (this.customerId.getValue()) {
      return;
    }

    const jwt = this.retrieveToken(storeChainId);
    if (jwt) {
      const {CustomerId} = decodeJwt(jwt);
      if (typeof CustomerId === 'string') {
        this.customerId.next(new CustomerIdPair(storeChainId, CustomerId));
      }
    }

    this.isSignedIn.next(Boolean(this.getAndValidateToken(storeChainId, true)));
  }

  async getCustomerId(storeChainId: string, cartService?: CartService): Promise<string> {
    if (this.pendingCustomerId) {
      return this.pendingCustomerId;
    }

    const customerId = this.customerId.getValue();
    if (customerId) {
      return customerId.value;
    }

    DevUtils.assert(
      this._currentStoreChainId != null,
      'Cannot retrieve currentStoreChainId without initializing customer auth first',
      (msg) => alert(msg),
    );

    if (!this.pendingCustomerId) {
      // Create and authenticate the new anonymous customer
      this.pendingCustomerId = this.customerProvider.createAnonymousCustomer(storeChainId).toPromise()
        .then(async (newCustomerId) => {
          // OrderIDs in cart storage will no longer belong to the customer; clear carts:
          cartService?.clearAllCarts();
          await this.customerAnonymousAuthenticate(storeChainId, newCustomerId);
          return newCustomerId;
        })
        .finally(() => this.pendingCustomerId = null);
    }

    return this.pendingCustomerId;
  }

  async customerAnonymousAuthenticate(storeChainId: string, customerId: string) {
    const response = await this.customerProvider.authenticateAnonymousCustomer(storeChainId, customerId).toPromise();
    await this.login(storeChainId, response.jwt);
  }

  async customerSmsLogin(storeChainId: string, phoneNumber: string): Promise<CustomerSmsLoginResponse> {
    return this.customerProvider.customerSmsLogin(storeChainId, phoneNumber).toPromise();
  }

  async customerSmsAuthenticate(storeChainId: string, phoneNumber: string, smsConfirmationCode: string) {
    const response = await this.customerProvider.customerSmsAuthenticate(storeChainId, phoneNumber, smsConfirmationCode).toPromise();
    await this.login(storeChainId, response.jwt);
  }

  async customerGoogleAuthenticate(storeChainId: string, googleJwt: string) {
    const response = await this.customerProvider.customerGoogleAuthenticate(storeChainId, googleJwt).toPromise();
    await this.login(storeChainId, response.jwt);
  }

  async customerVippsInitialize(storeChainId: string, returnPath: string) {
    return this.customerProvider.customerVippsInitialize(storeChainId, returnPath).toPromise();
  }

  async customerVippsAuthenticate(storeChainId: string, code: string) {
    const response = await this.customerProvider.customerVippsAuthenticate(storeChainId, code).toPromise();
    await this.login(storeChainId, response.jwt);
  }

  async login(storeChainId: string, jwt: string) {
    const {CustomerId, Anonymous} = decodeJwt(jwt);

    if (this._currentStoreChainId != storeChainId) {
      // currentStoreChainId should have already been set, but that's not the case with redirect from Vipps auth login
      this._currentStoreChainId = storeChainId;
    }

    if (this.isAnonymousUser(storeChainId) && Anonymous == 'False') {
      const lastKnownOrderId = this.orderService.getLastKnownOrder();

      if (lastKnownOrderId != null) {
        await this.orderService
          .transferOrder(lastKnownOrderId, CustomerId as string)
          .catch((error) => {
            Bugsnag.notify(
              {name: 'Failed to transfer order', message: `lastKnownOrderId: ${lastKnownOrderId} to customerId: ${CustomerId}`},
              event => event.addMetadata('Response', error),
            );
          });
      }
    }

    // Reset all variables first
    this.logout(storeChainId);

    this.storageService.store(`jwt_${storeChainId}`, jwt);
    this.customerId.next(new CustomerIdPair(storeChainId, CustomerId as string));
    this.isSignedIn.next(Anonymous == 'False');
  }

  logout(storeChainId: string) {
    if (this.orderService.getLastKnownOrder()) {
      this.orderService.clearLastKnownOrder();
    } else {
      this.orderService.clearMostRecentPurchase(storeChainId);
    }

    this.storageService.clear(`jwt_${storeChainId}`);

    // For backwards compatibility: clean up old vars
    this.storageService.clear('customer-id');
    this.storageService.clear('jwt-token');

    this.customerId.next(null);
    this.isSignedIn.next(false);
  }

  getAndValidateToken(storeChainId: string, ignoreAnon = false, maxAgeInSeconds?: number): string | false | null {
    const jwt = this.retrieveToken(storeChainId);
    if (!jwt) {
      return null;
    }

    const {exp, iat, Anonymous, aud, iss} = decodeJwt(jwt);
    if (!aud || !iss) {
      // JWT is missing audience and issuer; token is invalid
      return false;
    }

    const notTooOld = maxAgeInSeconds != undefined ? iat && iat + maxAgeInSeconds > Date.now() / 1000 : true;
    // Invalidate token a few seconds before it expires in order to prompt user for re-authentication before it ends
    const notExpired = exp && exp > (Date.now() / 1000) + 5;
    if (!ignoreAnon && Anonymous == 'True' && notExpired && notTooOld) {
      return jwt;
    }

    if (Anonymous == 'False' && notExpired && notTooOld) {
      return jwt;
    }
    // JWT is set, but invalid
    return false;
  }

  retrieveToken(storeChainId: string) {
    const jwt = this.storageService.retrieve(`jwt_${storeChainId}`);
    if (!jwt) {
      return null;
    }
    return jwt;
  }

  isAnonymousUser(storeChainId: string) {
    const jwt = this.retrieveToken(storeChainId);
    if (!jwt) {
      return null;
    }
    const {Anonymous} = decodeJwt(jwt);
    return Anonymous == 'True';
  }

  openLoginDialog(storeChainId: string, state?: LoginDialogState) {
    if (!this.loginDialogRef) {
      this.loginDialogRef = this.dialog.open(LoginDialog, {data: {state}, maxHeight: '100vh'});
      this.loginDialogRef.afterClosed().subscribe(result => {
        this.loginDialogRef = null;
        if (
          result != LoginDialogResult.Success &&
          state &&
          [LoginDialogState.LoginRequired, LoginDialogState.LoginExpired].includes(state)
        ) {
          this.logout(storeChainId);
        }
      });
    }
    return this.loginDialogRef;
  }
}

class CustomerIdPair {
  public readonly storeChainId: string;
  public readonly value: string;

  constructor(storeChainId: string, customerId: string) {
    this.storeChainId = storeChainId;
    this.value = customerId;
  }
}
